import type { Session as SupabaseSession } from "@supabase/supabase-js";

import { createContext, useContext } from "react";
import { useClientSession } from "./useSession";
import { useLoaderData } from "@remix-run/react";
type SupabaseContextProps = { session: SupabaseSession | null } | undefined;

export const SupbabaseClientContext =
  createContext<SupabaseContextProps>(undefined);

export const SupabaseSessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { session: clientSession } = useClientSession();

  const data = useLoaderData();

  const session =
    clientSession || (containsSession(data) ? data.session : null);

  return (
    <SupbabaseClientContext.Provider value={{ session }}>
      {children}
    </SupbabaseClientContext.Provider>
  );
};

export const useSupabaseSession = () => {
  const context = useContext(SupbabaseClientContext);
  if (context === undefined) {
    throw new Error(
      "useSupabaseSession must be used within a SupabaseSessionProvider"
    );
  }
  return context;
};

const containsSession = (
  data: unknown
): data is { session: SupabaseSession } => {
  return typeof data === "object" && data !== null && "session" in data;
};
